import store from '@/store'

// 判断是否移动端
const isMobileHandler = () => {
  let width =
    document.documentElement.clientWidth ||
    document.body.clientWidth ||
    window.innerWidth
  width && store.commit('bk/global/isMobileChange', width)
}

// 设置默认语言
const langsHandler = () => {
  let lang = window.navigator.language
  let langIndex = lang.includes('zh') ? 0 : 1
  store.commit('bk/global/langsChange', langIndex)
}

export { isMobileHandler, langsHandler }
