import { createStore } from "vuex";

import global from './modules/global'

const bk = {
  namespaced: true,
  modules: {
    global
  }
}

export default createStore({
  modules: {
    bk
  },
});
